import React from "react";
import PropTypes from "prop-types";
import Icon from "components/common/Icon";
import Popover from "components/common/Popover";

function SearchResultHeader({ text, infoText }) {
  return (
    <div className="results__heading">
      <h4>{text}</h4>
      <Popover
        inverted
        trigger={
          <div className="cursor-pointer">
            <Icon name="info" size={14} />
          </div>
        }
        position="center bottom"
        on="hover"
        popper={{ id: "popper-container", style: { zIndex: 19000 } }}
      >
        {infoText}
      </Popover>
      <Icon name="caret-right" size={12} />
    </div>
  );
}

SearchResultHeader.propTypes = {
  text: PropTypes.string.isRequired,
  infoText: PropTypes.string.isRequired
};
export default SearchResultHeader;
