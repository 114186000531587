import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Icon, Label, Popup } from "semantic-ui-react";
import "./styles.scss";
import axios from "axios";

function TaxonomyTerm({
  term,
  termTbdb,
  apiConfig,
  onAddTerm,
  onRemoveTerm,
  prevAddedTerms,
  dispatch,
  disabledGuids = []
}) {
  // List of terms to disable (excluded terms)
  const excludedTerms = [
    "Functional Practice Area",
    "Industry Practice Area",
    "Subjects",
    "Skills",
    "Tools and Solutions"
  ];

  // Check if the term is excluded (specific names)
  const isExcluded = excludedTerms.includes(term?.name);

  // Check if the term is globally disabled (based on guid)
  const isGloballyDisabled = disabledGuids.some((guidObj) => guidObj.guid === term.id);

  // Final disabled state: Combine exclusions and global disable
  const isDisabled = isExcluded || isGloballyDisabled;

  const getTermPath = useCallback(
    async (id) => {
      const endpoint = `${apiConfig?.apiEndpoint}?TBDB=${termTbdb}&service=term&template=service.json&id=${id}`;
      try {
        return await axios.get(endpoint, { headers: apiConfig?.headers });
      } catch (error) {
        console.error(`Failed to fetch data for ${termTbdb} with term ${id}:`, error);
        throw error;
      }
    },
    [termTbdb, apiConfig]
  );

  useEffect(() => {
    if (prevAddedTerms?.length) {
      prevAddedTerms?.forEach((item) => {
        const action = {
          type: "UPDATE_TERM",
          term: item
        };
        dispatch(action);
      });
    }
  }, [prevAddedTerms, dispatch]);

  const handleOnTermClick = async () => {
    if (isDisabled) return; // Do nothing if the term is disabled
    if (term?.isAdded) {
      onRemoveTerm(term);
      dispatch({
        type: "UPDATE_TERM",
        term: { ...term, isAdded: false }
      });
    } else {
      const termDetails = await getTermPath(term?.id);
      onAddTerm({
        ...term,
        isAdded: !term?.isAdded,
        path: termDetails?.data?.terms[0]?.term?.paths[0]?.path
      });
    }
  };

  const labelContent = (
    <Label
      className={`taxonomy__term--label ${term?.isAdded && "added"} ${isDisabled && "disabledTerm"}`}
      onClick={isDisabled ? undefined : handleOnTermClick}
    >
      {isDisabled ? (
        <Icon name="minus circle" color="grey" disabled />
      ) : (
        (!term?.isAdded && <Icon name="plus circle" />) || <Icon name="minus circle" className="minusIcon" />
      )}
      {term?.name || ""}
    </Label>
  );

  // Render the label with a popup for globally disabled terms
  if (isGloballyDisabled) {
    return (
      <Popup
        position="right center"
        inverted
        content="Already added in your Profile"
        trigger={labelContent}
        className="clipboard-tooltip"
      />
    );
  }

  // Render the label without any popup for excluded terms
  return labelContent;
}

TaxonomyTerm.defaultProps = {
  term: {},
  prevAddedTerms: [],
  onAddTerm: () => {},
  onRemoveTerm: () => {}
};

TaxonomyTerm.propTypes = {
  term: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    isAdded: PropTypes.bool,
    isSkill: PropTypes.bool
  }),
  apiConfig: PropTypes.shape({
    headers: PropTypes.shape({
      authorization: PropTypes.string.isRequired,
      noAuthHeader: PropTypes.bool.isRequired
    }),
    apiEndpoint: PropTypes.string.isRequired
  }).isRequired,
  prevAddedTerms: PropTypes.arrayOf(PropTypes.shape({})),
  dispatch: PropTypes.func.isRequired,
  onAddTerm: PropTypes.func,
  onRemoveTerm: PropTypes.func,
  termTbdb: PropTypes.string.isRequired
};

export default TaxonomyTerm;
