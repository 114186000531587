import { Button, List, ListItem, Modal } from "semantic-ui-react";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { getParsedToken } from "auth/utils";
import TaxonomySearch from "components/common/TaxonomySearch";
import CONFIG from "constants/config";
import TaxonomyTerm from "components/common/TaxonomySearch/TaxonomyTerm/TaxonomyTerm";
import withPopup from "components/HOCs/withPopup/withPopup";
import TermPath from "components/common/TaxonomySearch/TermPath/TermPath";
import DragAndDrop from "./DragAndDrop";
import LABEL from "../../constants/label";
import { copyObject } from "pages/helpers/utils";
import { retainInitialState, getExistingGuids } from "pages/AffiliationsPage/helper/utils";

import "./styles.scss";

const { ADD_EXPERTISE } = LABEL.EXPERTISE;
const AddExpertise = ({
  open,
  onModalClose,
  data,
  filterApplied,
  isEditable,
  viewExpertiseData,
  refreshExpertiseData,
  handleClearAllFilter
}) => {
  const {
    cookies: { SMART_LOGIC_TOKEN }
  } = CONFIG;

  const smartLogicToken = getParsedToken(SMART_LOGIC_TOKEN);

  const apiConfig = {
    headers: {
      authorization: `Bearer ${smartLogicToken?.access_token}`,
      noAuthHeader: true
    },
    apiEndpoint: CONFIG.apiUrl.smartLogicApi
  };

  const tbdbList = [
    {
      key: "industryPA",
      title: "Industry",
      tbdb: "IndustryPracticeArea0",
      infoText: "Represents PA sectors and topics where BCG allocates revenue/business."
    },
    {
      key: "functionPA",
      title: "Function",
      tbdb: "FunctionalPracticeArea",
      infoText: "Represents PA sectors and topics where BCG allocates revenue/business."
    },
    {
      key: "subjectPA",
      title: "Subject",
      tbdb: "Subjects",
      infoText: "Represents a subject matter expertise in a niche topic within a PA, industry or sector."
    },
    {
      key: "skills",
      title: "Skills",
      tbdb: "Skills",
      infoText: "Represents terms to describe capabilities or actions."
    },
    {
      key: "toolsAndSolutions",
      title: "Tools",
      tbdb: "ToolsAndSolutions",
      infoText: "Represents terms to describe capabilities or actions."
    }
  ];

  const TermWithPopup = withPopup(TaxonomyTerm);

  const [newlyAddedTerms, setNewlyAddedTerms] = useState([]);
  const [showSecondPop, setShowSecondPop] = useState(false);
  const [taxonomyActionDispatch, setTaxonomyActionDispatch] = useState(null);
  const [showSearchOption, setShowSearchOption] = useState(false);
  const [_data, _setData] = useState([]);
  const [existingGuidData, setExistingGuidData] = useState([]);

  useEffect(() => {
    if (_data && _data.length > 0) {
      const existingGuids = getExistingGuids(filterApplied, _data, viewExpertiseData);
      setExistingGuidData(existingGuids);
    }
  }, [_data]);

  useEffect(() => {
    if (refreshExpertiseData) {
      const newData = retainInitialState(_data, data);
      _setData(newData);
    }
  }, [refreshExpertiseData]);

  useEffect(() => {
    if (!isEditable) {
      const newData = copyObject(data);
      _setData(newData);
    }
  }, [isEditable]);

  const handleSearchOption = () => {
    setShowSearchOption(true);
  };
  const handleNoSearchOption = () => {
    setShowSearchOption(false);
  };

  const handleClose = () => {
    onModalClose();
  };

  const handleAddTermDebounced = debounce((term) => {
    setNewlyAddedTerms((prev) => {
      const isTermAlreadyAdded = prev.some((item) => item.id === term.id);
      if (isTermAlreadyAdded) return prev;
      return [...prev, term];
    });
  }, 300); // Debounce for 300ms

  const handleAddTerm = (term) => {
    handleAddTermDebounced(term);
  };

  const handleRemoveTerm = (term) => {
    setNewlyAddedTerms(newlyAddedTerms.filter((item) => item?.id !== term?.id));
  };

  const nextHandle = () => {
    setShowSecondPop(true);
  };
  const prevHandle = () => {
    setShowSecondPop(false);
    setShowSearchOption(false);
  };
  const handleClearAll = () => {
    setNewlyAddedTerms([]);
    newlyAddedTerms.forEach((term) => {
      taxonomyActionDispatch?.dispatch({
        type: "UPDATE_TERM",
        term: { ...term, isAdded: false }
      });
    });
  };
  return (
    <div>
      {showSecondPop ? (
        <div className="page2">
          <div>
            <DragAndDrop
              open={open}
              onModalClose={onModalClose}
              prevHandle={prevHandle}
              data={newlyAddedTerms}
              handleRemoveTerm={handleRemoveTerm}
              cbSubmit
              handleClearAllFilter={handleClearAllFilter}
            />
          </div>
        </div>
      ) : (
        <Modal open={open} className="addExpertise__popup">
          <Modal.Content>
            <div className="addExpertise__popup-heading">
              <div>
                <h1>{ADD_EXPERTISE.AddYourExpertise}</h1>
                <p className="subHeading"> {ADD_EXPERTISE.SearchAndAdd}</p>
              </div>
              <div>
                <img
                  src="./icons/cross-icon-black.svg"
                  onClick={handleClose}
                  className="cancel_icon"
                  alt="close"
                  title="close"
                  role="presentation"
                />
              </div>
            </div>

            <div className="page1">
              <TaxonomySearch
                placeholder="Search"
                apiConfig={apiConfig}
                tbdbList={tbdbList}
                onAddTerm={handleAddTerm}
                onRemoveTerm={handleRemoveTerm}
                setTaxonomyActionDispatch={setTaxonomyActionDispatch}
                prevAddedTerms={newlyAddedTerms}
                searchOption={handleSearchOption}
                noSearchOption={handleNoSearchOption}
                disabledGuids={existingGuidData}
              />
              {/* {!!suggestedExpertise?.length && (
                <div className="addExpertise__popup-suggestedExpertise">
                  <p>
                    <img src="./icons/react-logo.svg" alt="react-logo" title="logo" /> Suggested Expertise based on your
                    recent cases, profile summary
                  </p>
                  <div className="expertise_section">
                    {suggestedExpertise.map((expertise) => {
                      return (
                        <div key={`${expertise?.pa}_${expertise.id}`}>
                          <div>
                            <Label name={expertise?.pa} plus="true" />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )} */}

              {!!newlyAddedTerms?.length > 0 && (
                <div
                  className={
                    showSearchOption
                      ? "addExpertise__popup-additional_section"
                      : "addExpertise__popup-additional_section_"
                  }
                >
                  <div className="addExpertise__popup-additional_sec_heading">
                    <p>
                      <strong> {ADD_EXPERTISE.AddedPASkillsExpertise}</strong>
                    </p>
                    <Button onClick={handleClearAll} className="clearall-btn">
                      <img src="./icons/clear-all.svg" alt="clearAll" title="clearAll" />
                    </Button>
                  </div>
                  <div className="expertise_section">
                    <List horizontal>
                      {newlyAddedTerms
                        .slice()
                        .reverse()
                        .map((term) => (
                          <ListItem key={term?.id}>
                            <TermWithPopup
                              popupContent={<TermPath path={term?.path} />}
                              term={term}
                              onRemoveTerm={handleRemoveTerm}
                              dispatch={taxonomyActionDispatch?.dispatch}
                            />
                          </ListItem>
                        ))}
                    </List>
                  </div>
                  <div className="align-items-center text-align-center">
                    <Button className="next_button" onClick={nextHandle}>
                      {ADD_EXPERTISE.AddProficiency}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Modal.Content>
        </Modal>
      )}
    </div>
  );
};
AddExpertise.defaultProps = {
  open: false,
  onModalClose: () => {},
  handleClearAllFilter: () => {}
};

AddExpertise.propTypes = {
  open: PropTypes.bool,
  onModalClose: PropTypes.func,
  handleClearAllFilter: PropTypes.func
};

export default AddExpertise;
