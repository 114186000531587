import Search from "components/common/Search";

import { isXS, isSM, isMD } from "pages/helpers/utils";
import { useState } from "react";
import "./ExpertiseTitleSearch.scss";
import { Card } from "semantic-ui-react";

const ExpertiseTitleSearch = ({ onSearch }) => {
  const [searchBox, setSearchBox] = useState(false);
  const isSmallScreen = isXS() || isSM();
  const isMediumScreen = isMD();
  const clickHandler = () => {
    setSearchBox(!searchBox);
  };
  const handleSearch = (query) => {
    onSearch(query);
  };
  return (
    <Card.Content>
      {searchBox ? (
        (!isSmallScreen || isMediumScreen) && (
          <Search
            placeholder="Search Expertise Title"
            onSearch={handleSearch}
            classN="invertedExpertise"
            clickHandle={clickHandler}
            expertiseSearch="true"
          />
        )
      ) : (
        <div className="ExpertiseTitleSearch">
          <div>Expertise Title</div>
          <div onClick={clickHandler} className="ExpertiseSearch" />
        </div>
      )}
    </Card.Content>
  );
};
export default ExpertiseTitleSearch;
