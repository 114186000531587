import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Image, Label, List, Table, Icon, Button, Loader, Dimmer, Segment, TableBody } from "semantic-ui-react";
import { copyToClipboard, formatDateByMoment } from "helpers/utils";
import { useSelector, useDispatch } from "react-redux";
import { getExpertise, getExpertiseData } from "redux/actions/expertise";
import CONFIG from "constants/config";
import LABEL from "constants/label";
import stripHtml from "string-strip-html";
import { Popover, Menu } from "components/common";
import ExpandButton from "../ExpandButton";
import ExpertiseProficiency from "./ExpertiseProficiency";
import Pagination from "../Pagination";
import FilterIcon from "../FilterIcon/FilterIcon";
import ExpertiseFilters from "../ExpertiseFilters/ExpertiseFilters";
import "./styles.scss";
import DeleteExpertise from "./DeleteExpertise";
import AddExpertise from "components/AddExpertise";

const { apiUrl, xApiKey, cases, knowledge } = CONFIG;
const { MATERIALS } = LABEL;
const paFiltersData = [
  { label: "Practice Areas", value: "Practice+Area" },
  { label: "Subject", value: "Subjects" },
  { label: "Skills & Tools", value: "Skills" }
];

const paafFiltersData = [
  { label: "Interested", value: "Interested" },
  { label: "Lead", value: "Lead" },
  { label: "Active", value: "Active" }
];
function AccordionTable({ user, isEditable, peopleData, hasExpertiseData, handleAddExpertise }) {
  const dispatch = useDispatch();
  const {
    expertiseData,
    expertiseDataLoading,
    expertiseDropDown,
    expertiseDropDownLoading,
    topicUpdatingSuccess,
    topicUpdating,
    skillUpdating,
    skillUpdatingSuccess
  } = useSelector((state) => state);

  const data = expertiseData?.[0]?.expertise ? expertiseData[0]?.expertise : [];
  const caseData = expertiseDropDown?.[0]?.cases ? expertiseDropDown[0]?.cases : [];
  const materialData = expertiseDropDown?.[0]?.materials ? expertiseDropDown[0]?.materials : [];
  const [activeIndex, setActiveIndex] = useState(null);
  const itemsPerPage = 5;
  const itemsPerPageExpertise = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [currentCasePage, setCurrentCasePage] = useState(1);
  const [currentMaterialPage, setCurrentMaterialPage] = useState(1);
  const [showAll, setShowAll] = useState(false);
  const [expertiseGuid, setExpertiseGuid] = useState(null);
  const [isDropDownActive, setIsDropDownActive] = useState(false);
  const [expertiseApiRefresh, setExpertiseApiRefresh] = useState(false);
  const [sortKey, setSortKey] = useState(null); // Sorting key
  const [sortOrder, setSortOrder] = useState(null); // Sorting order
  const [paafFilters, setPaafFilters] = useState([]); // State for PAAF filters
  const [paFilters, setPaFilters] = useState([]); // State for PA filters
  const [open, setOpen] = useState(false);

  const toggleExpertise = () => {
    setExpertiseApiRefresh(!expertiseApiRefresh);
    setActiveIndex(null);
  };
  useEffect(() => {
    // Construct `filterParent` query for PA filters
    const filterParentQuery = paFilters.length > 0 ? `&filterParent=${paFilters.join(",")}` : "";

    // Construct `filterPaaf` query for PAAF filters
    const filterQuery = paafFilters.length > 0 ? `&filterPaaf=${paafFilters.join(",")}` : "";

    // Sorting query
    const sortQuery = sortKey ? `&sortOrder=${sortOrder}&sortKey=${sortKey}` : "";

    // Combine all queries
    const endpoint = `${apiUrl.linkApi}profile/${user?.hrId}/expertise-paaf-tpoc?page=${currentPage}&pageSize=${itemsPerPageExpertise}${sortQuery}${filterQuery}${filterParentQuery}`;

    // Dispatch the API call
    dispatch(
      getExpertise({
        endpoint,
        config: {
          headers: {
            "x-api-key": `${xApiKey.profile}`
          }
        }
      })
    );
  }, [user?.hrId, currentPage, sortKey, sortOrder, paafFilters, paFilters, expertiseApiRefresh, topicUpdatingSuccess]);

  // Handle Filter Changes
  const handlePaafFilterChange = (filterValue, isChecked) => {
    setPaafFilters(
      (prevFilters) =>
        isChecked
          ? [...prevFilters, filterValue] // Add filter if checked
          : prevFilters.filter((filter) => filter !== filterValue) // Remove if unchecked
    );
  };
  const handlePaFilterChange = (filterValue, isChecked) => {
    setPaFilters(
      (prevFilters) =>
        isChecked
          ? [...prevFilters, filterValue] // Add filter if checked
          : prevFilters.filter((filter) => filter !== filterValue) // Remove if unchecked
    );
  };

  useEffect(() => {
    if (isDropDownActive) {
      dispatch(
        getExpertiseData({
          endpoint: `${apiUrl.linkApi}profile/${user?.hrId}/expertise/${expertiseGuid}?cases-page=${currentCasePage}&cases-size=${itemsPerPage}&materials-page=${currentMaterialPage}&materials-size=${itemsPerPage}`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
    }
  }, [expertiseGuid, user?.hrId, currentMaterialPage, currentCasePage]);

  // expertise Pagination
  const [paginationData, setPaginationData] = useState({
    activePage: 1,
    start: 0,
    end: itemsPerPageExpertise
  });

  const onPaginate = (e, { activePage }) => {
    setActiveIndex(null);
    const end = activePage * itemsPerPageExpertise;
    const start = end - itemsPerPageExpertise;
    setCurrentPage(activePage);

    setPaginationData({
      start,
      end,
      activePage
    });
  };
  const { start, end, activePage } = paginationData;

  const expertiseDataList = showAll ? data : data.slice(0, 5);
  const onClickShowAll = () => {
    setShowAll(!showAll);
  };

  // Materials Pagination
  const [paginationMaterialsData, setPaginationMaterialsData] = useState({
    activePageM: 1,
    startMaterials: 0,
    endMaterials: itemsPerPage
  });

  const onPaginateMaterials = (e, { activePage }) => {
    const endMaterials = activePage * itemsPerPage;
    const startMaterials = endMaterials - itemsPerPage;
    const activePageM = activePage;
    setCurrentMaterialPage(activePage);

    setPaginationMaterialsData({
      startMaterials,
      endMaterials,
      activePageM
    });
  };
  const { startMaterials, endMaterials, activePageM } = paginationMaterialsData;

  // Cases Pagination
  const [paginationCasesData, setPaginationCasesData] = useState({
    activePageC: 1,
    startCases: 0,
    endCases: itemsPerPage
  });

  const onPaginateCases = (e, { activePage }) => {
    const endCases = activePage * itemsPerPage;
    const startCases = endCases - itemsPerPage;
    const activePageC = activePage;
    setCurrentCasePage(activePage);

    setPaginationCasesData({
      startCases,
      endCases,
      activePageC
    });
  };
  const { startCases, endCases, activePageC } = paginationCasesData;

  const handleAccordionClick = (index, expertiseGuId) => {
    setPaginationCasesData({ activePageC: 1 });
    setPaginationMaterialsData({ activePageM: 1 });
    setCurrentCasePage(1);
    setCurrentMaterialPage(1);
    setActiveIndex(activeIndex === index ? null : index);
    setExpertiseGuid(expertiseGuId);
    setIsDropDownActive(true);
  };
  const handleSorting = (newSortKey) => {
    if (sortKey === newSortKey) {
      // Reset sorting to default state
      setSortKey(null);
      setSortOrder(null);
    } else {
      // Toggle sorting order or set new sorting key
      setSortKey(newSortKey);
      setSortOrder(sortOrder === "desc" ? "asc" : "desc");
    }
  };

  return (
    <Table className="accordion-table">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={2}>
            <div className="d-flex">
              PA/Subject/Skill{" "}
              <FilterIcon
                filterContents={
                  <ExpertiseFilters
                    checkboxes={paFiltersData} // Replace with your PA filter options
                    onFilterChange={handlePaFilterChange} // Use PA filter handler
                    selectedFilters={paFilters}
                  />
                }
                disabled={!hasExpertiseData || expertiseData.length === 0}
                hasFilters={paFilters.length > 0}
              />
            </div>
          </Table.HeaderCell>
          <Table.HeaderCell width={1} />
          <Table.HeaderCell width={6}>Expertise Title</Table.HeaderCell>
          <Table.HeaderCell width={3}>
            Materials
            <span>
              <Icon
                name="sort"
                onClick={() => handleSorting("materials")}
                color={sortKey === "materials" ? (sortOrder === "desc" ? "black" : "green") : "green"}
                className={`cursor-pointer ${!hasExpertiseData || expertiseData.length === 0 ? "disabled" : ""}`}
              />
            </span>
          </Table.HeaderCell>
          <Table.HeaderCell width={1} />
          <Table.HeaderCell width={3}>
            Cases
            <Icon
              name="sort"
              onClick={() => handleSorting("cases")}
              color={sortKey === "cases" ? (sortOrder === "desc" ? "black" : "green") : "green"}
              className={`cursor-pointer ${!hasExpertiseData || expertiseData.length === 0 ? "disabled" : ""}`}
            />
          </Table.HeaderCell>
          <Table.HeaderCell width={1} />

          <Table.HeaderCell width={3}>
            <div className="d-flex">
              PAAF{" "}
              <FilterIcon
                filterContents={
                  <ExpertiseFilters
                    checkboxes={paafFiltersData} // Replace with your PAAF filter options
                    onFilterChange={handlePaafFilterChange} // Use PAAF filter handler
                    selectedFilters={paafFilters}
                  />
                }
                disabled={!hasExpertiseData || expertiseData.length === 0}
                hasFilters={paafFilters.length > 0}
              />
            </div>
          </Table.HeaderCell>
          <Table.HeaderCell width={1} />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {expertiseDataLoading ? (
          <Segment className="case-details__loadingSegment">
            <Loader size="large" active />
          </Segment>
        ) : hasExpertiseData ? (
          expertiseDataList.map((expertiseItem, index) => (
            <React.Fragment key={expertiseItem?.title}>
              <Table.Row>
                <Table.Cell>{expertiseItem?.topicName}</Table.Cell>
                <Table.Cell />
                <Table.Cell>
                  <Label>{expertiseItem?.expertiseName}</Label>
                </Table.Cell>
                <Table.Cell>{expertiseItem?.totalMaterials}</Table.Cell>
                <Table.Cell />
                <Table.Cell>{expertiseItem?.totalCases}</Table.Cell>
                <Table.Cell />
                <Table.Cell>{expertiseItem?.affiliation}</Table.Cell>
                <Table.Cell className="cursor-pointer">
                  <ExpandButton onClick={() => handleAccordionClick(index, expertiseItem?.expertiseGuid)} circular />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell colSpan="9" className="p-0" style={{ borderBottom: "none", borderTop: "none" }}>
                  <div className={`panel ${activeIndex === index ? "show" : ""}`}>
                    <Table className="proficiency__topic">
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell width={3}>Proficiency</Table.HeaderCell>
                          <Table.HeaderCell width={2} />
                          <Table.HeaderCell width={5}>Topic Role</Table.HeaderCell>
                          <Table.HeaderCell width={5} />
                          <Table.HeaderCell width={5} />
                          <Table.HeaderCell width={9} />
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>
                            <ExpertiseProficiency
                              expertiseProficiency={expertiseItem?.proficiency}
                              isEditable={isEditable}
                              topic={expertiseItem?.topic}
                              expertiseGuid={expertiseItem?.expertiseGuid}
                              peopleData={peopleData}
                              staffExpertiseId={expertiseItem?.staffExpertiseId}
                              designatedBy={expertiseItem?.designatedBy}
                              createdFrom={expertiseItem?.createdFrom}
                              lastUpdateFrom={expertiseItem?.lastUpdateFrom}
                              toggleExpertise={toggleExpertise}
                            />
                          </Table.Cell>
                          <Table.Cell />
                          <Table.Cell>
                            {expertiseItem?.designatedBy == "1"
                              ? "Self Designated"
                              : expertiseItem?.topic
                              ? expertiseItem?.topic
                              : "-"}
                          </Table.Cell>
                          <Table.Cell>
                            {expertiseItem?.designatedBy == "1" && (
                              <DeleteExpertise
                                expertiseProficiency={expertiseItem?.proficiency}
                                isEditable={isEditable}
                                topic={expertiseItem?.topic}
                                expertiseGuid={expertiseItem?.expertiseGuid}
                                peopleData={peopleData}
                                staffExpertiseId={expertiseItem?.staffExpertiseId}
                                designatedBy={expertiseItem?.designatedBy}
                                createdFrom={expertiseItem?.createdFrom}
                                lastUpdateFrom={expertiseItem?.lastUpdateFrom}
                                toggleExpertise={toggleExpertise}
                              />
                            )}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                    {expertiseDropDownLoading ? (
                      <Segment className="case-details__loadingSegment">
                        <Loader size="large" active />
                      </Segment>
                    ) : (
                      <>
                        <List className="case-list">
                          <List.Header>
                            Cases <span>({expertiseItem?.totalCases})</span> & Hours{" "}
                            <span>({expertiseDropDown[0]?.totalCaseHours})</span>
                          </List.Header>
                          {caseData?.map((taggedCase) => (
                            <List.Item key={taggedCase?.caseTitle}>
                              <Image src="/icons/case-icon.svg" />
                              <List.Content className="cases-container">
                                <a href={`${cases.expertiseCasesPageUrl}${taggedCase?.knGuid}`} target="_blank">
                                  {taggedCase?.caseTitle}
                                </a>{" "}
                                (<strong>{taggedCase?.hoursSpentByHrIdOnCase}</strong> |{" "}
                                {formatDateByMoment(taggedCase?.caseOpenDate, "MMM, YYYY")} -{" "}
                                {formatDateByMoment(taggedCase?.caseClosedDate, "MMM, YYYY")})
                              </List.Content>
                            </List.Item>
                          ))}
                          {expertiseDropDown[0]?.totalCasesPages > 1 && (
                            <List.Item>
                              {" "}
                              <div>
                                <Pagination
                                  itemName="Cases"
                                  totalPages={expertiseDropDown[0]?.totalCasesPages}
                                  totalElementCount={expertiseDropDown[0]?.totalCases}
                                  onPageChange={onPaginateCases}
                                  startPageNumber={startCases}
                                  endPageNumber={endCases}
                                  activePage={activePageC}
                                />
                              </div>
                            </List.Item>
                          )}
                        </List>

                        <List className="material-list">
                          <List.Header>
                            Materials <span>({expertiseItem?.totalMaterials})</span>
                          </List.Header>
                          {expertiseItem?.totalMaterials > 0 && (
                            <>
                              {materialData?.map((material) => {
                                const pageLink = `${knowledge.kp}/${material?.kpCmsId}`;
                                const attachmentLink = `${knowledge.attachment}/${material?.kpCmsId}`;
                                const downloadLink = `${knowledge.download}/${material?.kpCmsId}`;
                                let body =
                                  material?.materialDescriptionHtml.length > 0
                                    ? stripHtml(material?.materialDescriptionHtml.trim()).result
                                    : "";
                                let newTitle = material?.materialTitle;
                                if (newTitle.length > 100) {
                                  newTitle = `${newTitle.substr(0, 100).trim()}...`;
                                }

                                // let newFileName = material?.resultType === "KPU" ? urlTitle : fileName;
                                // if (newFileName.length > 342) {
                                //   newFileName = `${newFileName.substr(0, 342).trim()}...`;
                                // }

                                if (body.length > 500) {
                                  body = `${body.substr(0, 500)}...`;
                                }

                                body = `${MATERIALS.DirectDownload}:
                        ${downloadLink}
                                
                        ${body}
                                
                        ${MATERIALS.FullDetails}:
                        ${attachmentLink}
                                  `;
                                body = encodeURIComponent(body);

                                const trigger = (
                                  <div className="m-item__share__item">
                                    <Popover
                                      trigger={<Image src="/icons/share-filled.svg" className="mr-h cursor-pointer" />}
                                      on="hover"
                                      inverted
                                      position="bottom center"
                                      size="tiny"
                                      popper={{ className: "xs " }}
                                    >
                                      {MATERIALS.Share}
                                    </Popover>
                                  </div>
                                );

                                const menuItems = [];
                                menuItems.push(
                                  {
                                    name: MATERIALS.ShareViaEmail,
                                    onClick: () => {
                                      window.location.href = `mailto:?subject=${MATERIALS.SharedFromNavigator}: ${material?.materialTitle}&body=${body}`;
                                    }
                                  },
                                  {
                                    name: MATERIALS.CopyLinkToKnowledgePage,
                                    onClick: () => {
                                      copyToClipboard(pageLink);
                                    },
                                    copiedText: MATERIALS.Copied
                                  },
                                  {
                                    name: MATERIALS.CopyLinkToAttachment,
                                    onClick: () => {
                                      copyToClipboard(attachmentLink);
                                    },
                                    copiedText: MATERIALS.Copied
                                  }
                                );
                                return (
                                  <List.Item key={material?.materialTitle} className="materials-container">
                                    <List.Content>
                                      <Icon name="ppt" className="mr-h mb-1" />
                                      <div className="main-content">
                                        <a href={`${knowledge.kpExpertise}/${material?.kpCmsId}`} target="_blank">
                                          {material?.materialTitle}
                                        </a>
                                        <p>
                                          Updated on {formatDateByMoment(material?.lastUpdatedDate, "MMM DD, YYYY")}
                                        </p>
                                      </div>
                                      <div className="list-actions">
                                        <Menu menuItems={menuItems} trigger={trigger} />
                                        <a
                                          href={`${knowledge.downloadExpertise}/${material?.kpCmsId}`}
                                          aria-label="download"
                                        >
                                          <Image src="/icons/download-arrow.svg" />
                                        </a>
                                      </div>
                                    </List.Content>
                                  </List.Item>
                                );
                              })}
                              {expertiseDropDown[0]?.totalMaterialsPages > 1 && (
                                <List.Item>
                                  <Pagination
                                    itemName="Materials"
                                    totalPages={expertiseDropDown[0]?.totalMaterialsPages}
                                    totalElementCount={expertiseDropDown[0]?.totalMaterials}
                                    onPageChange={onPaginateMaterials}
                                    startPageNumber={startMaterials}
                                    endPageNumber={endMaterials}
                                    activePage={activePageM}
                                  />
                                </List.Item>
                              )}
                            </>
                          )}
                        </List>
                      </>
                    )}
                  </div>
                </Table.Cell>
              </Table.Row>
            </React.Fragment>
          ))
        ) : paFilters.length > 0 || paafFilters.length > 0 ? (
          // Display "No Results Found" when filters are applied but no results exist
          <Table.Row>
            <Table.Cell colSpan="12">
              <div className="No__expertise">
                <h3>No Results Found</h3>
              </div>
            </Table.Cell>
          </Table.Row>
        ) : (
          // Default "No Expertise Added" when no filters are applied and no data exists
          <Table.Row>
            <Table.Cell colSpan="12">
              <div className="No__expertise">
                <h3>No Expertise Added</h3>
                <Button
                  className="add__expertise--btn"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Add Expertise
                </Button>
                <p>to showcase your skills and knowledge.</p>
              </div>
            </Table.Cell>
          </Table.Row>
        )}
        {open && <AddExpertise open={true} onModalClose={() => setOpen(false)} />}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.Cell colSpan="12">
            {expertiseData?.[0]?.totalPages > 1 && showAll && (
              <div>
                <Pagination
                  itemName="expertise"
                  totalPages={expertiseData[0]?.totalPages}
                  totalElementCount={expertiseData?.[0]?.totalExpertise}
                  onPageChange={onPaginate}
                  startPageNumber={start}
                  endPageNumber={end}
                  activePage={activePage}
                />
              </div>
            )}
            {hasExpertiseData && (
              <Button onClick={onClickShowAll} className="btn__expand ">
                <img src="/icons/caret-down-green.svg" alt="arrow down" className="mr-h" />
                <span> {showAll ? "Show less expertise" : "Show all expertise"}</span>
              </Button>
            )}
          </Table.Cell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
}
AccordionTable.defaultProps = {
  user: {},
  isEditable: false
};
AccordionTable.propTypes = {
  user: PropTypes.shape({
    hrId: PropTypes.number
  }),
  isEditable: PropTypes.bool,
  peopleData: PropTypes.shape().isRequired
};

export default AccordionTable;
