import PropTypes from "prop-types";
import "./styles.scss";

const Label = ({ name, minus, handleRemoveFromBox, boxLabel, handleRemoveFromIntial }) => {
  return (
    <p className="Label">
      <img
        src={minus ? "./icons/minus-small-circle-filled.svg" : "./icons/plus-small-circle-filled.svg"}
        alt="circle"
        title="circle"
        onClick={boxLabel ? handleRemoveFromBox : handleRemoveFromIntial}
      />
      <p>{name}</p>
    </p>
  );
};
Label.defaultProps = {
  name: "",
  minus: false,
  handleRemoveFromBox: () => {},
  boxLabel: "",
  handleRemoveFromIntial: () => {}
};

Label.propTypes = {
  name: PropTypes.string,
  minus: PropTypes.bool,
  handleRemoveFromBox: PropTypes.func,
  boxLabel: PropTypes.string,
  handleRemoveFromIntial: PropTypes.func
};
export default Label;
