import React from "react";
import { Checkbox } from "semantic-ui-react";
import "./styles.scss";

const ExpertiseFilters = ({ checkboxes, onFilterChange, selectedFilters }) => {
  const handleCheckboxChange = (e, { value, checked }) => {
    onFilterChange(value, checked);
  };

  return (
    <div className="filters__items">
      {checkboxes?.map((checkbox) => (
        <div key={checkbox.value} className="filters__item">
          <Checkbox
            label={checkbox.label}
            value={checkbox.value}
            checked={selectedFilters.includes(checkbox.value)} // Ensure the checkbox state is tied to selectedFilters
            onChange={handleCheckboxChange}
          />
        </div>
      ))}
    </div>
  );
};

export default ExpertiseFilters;
