import Label from "components/common/ExpertiseLabel/Label";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dimmer, Loader, Modal, List, ListItem, Segment, Icon } from "semantic-ui-react";
import { setUpdateTopicSuccess, updateTopic } from "redux/actions/topics";
import { setUpdateSkillSuccess, updateSkill } from "redux/actions/skills";
import { toastError } from "helpers/utils";
import { toast } from "react-semantic-toasts";
import CONFIG from "constants/config";
import LABEL from "constants/label";
import { Popover } from "components/common";
import TermPath from "components/common/TaxonomySearch/TermPath/TermPath";
import withPopup from "components/HOCs/withPopup/withPopup";

import "./styles.scss";
import BOXES from "./proficiencyData";

const { COMMON } = LABEL;
const { ADD_EXPERTISE } = LABEL.EXPERTISE;

const DragAndDrop = ({ open, onModalClose, prevHandle, data, handleRemoveTerm, handleClearAllFilter }) => {
  const { apiUrl, xApiKey } = CONFIG;
  const dispatch = useDispatch();
  const {
    topicUpdating,
    skillUpdating,
    topicUpdatingError,
    topicUpdatingSuccess,
    skillUpdatingSuccess,
    skillUpdatingError,
    peopleData
  } = useSelector((state) => state);

  const [items, setItems] = useState(data);
  const [boxes, setBoxes] = useState(BOXES());
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (isSubmitting) {
      if (topicUpdatingSuccess || skillUpdatingSuccess) {
        toast({
          type: "success",
          icon: COMMON.SuccessMsgIcon,
          title: COMMON.SuccessMsgHeader,
          description: COMMON.SuccessMsgDesc,
          animation: "fly left",
          time: 15000
        });
        dispatch(setUpdateTopicSuccess());
        dispatch(setUpdateSkillSuccess());
        onModalClose();
        handleClearAllFilter();
      }

      if (topicUpdatingError || skillUpdatingError) {
        toastError();
      }
    }
  }, [topicUpdatingSuccess, topicUpdatingError, skillUpdatingSuccess, skillUpdatingError]);

  const handleSubmit = () => {
    setIsSubmitting(true);
    const expertiseList = boxes.flatMap((box) => box.items.filter((item) => !item.isSkill));
    const skillList = boxes.flatMap((box) => box.items.filter((item) => item.isSkill));

    const expertisePayload = expertiseList.map(({ id, proficiency }) => ({
      expertiseGuid: id,
      proficiency,
      designatedBy: 1,
      createdFrom: 1,
      lastUpdateFrom: 1
    }));

    const skillPayload = skillList.map(({ id, proficiency }) => ({
      skillGuid: id,
      proficiency,
      designatedBy: 1,
      createdFrom: 1,
      lastUpdateFrom: 1
    }));

    if (expertisePayload.length) {
      dispatch(
        updateTopic({
          endpoint: `${apiUrl.linkApi}profile/${peopleData?.id}/expertise`,
          data: { expertise: expertisePayload },
          config: { headers: { "x-api-key": xApiKey.profile } }
        })
      );
    }
    if (skillPayload.length) {
      dispatch(
        updateSkill({
          endpoint: `${apiUrl.linkApi}profile/${peopleData?.id}/skill`,
          data: { skill: skillPayload },
          config: { headers: { "x-api-key": xApiKey.profile } }
        })
      );
    }
  };

  const handleDragStart = (e, itemId, fromBoxId = "") => {
    e.dataTransfer.setData("itemId", itemId);
    e.dataTransfer.setData("fromBoxId", fromBoxId);
  };

  const handleDrop = (e, toBoxId) => {
    e.preventDefault();
    const itemId = e.dataTransfer.getData("itemId");
    const fromBoxId = e.dataTransfer.getData("fromBoxId");

    let item;
    if (fromBoxId && fromBoxId !== "initial") {
      // Dragging item from one of the boxes
      const sourceBox = boxes.find((box) => box.id === parseInt(fromBoxId, 10));
      item = sourceBox?.items.find((i) => i.id.toString() === itemId);
    } else {
      // Dragging item from the initial items list
      item = items.find((i) => i.id.toString() === itemId);
    }

    if (item) {
      // Remove the item from its source
      const updatedBoxes = boxes.map((box) => {
        if (box.id === parseInt(fromBoxId, 10)) {
          return { ...box, items: box.items.filter((i) => i.id.toString() !== itemId) };
        }
        return box;
      });

      if (toBoxId === "initial") {
        // Drop into the initial items list, ensuring the item is added back only if it doesn’t already exist
        if (!items.some((i) => i.id.toString() === itemId)) {
          setItems((prevItems) => [...prevItems, item]);
        }
      } else {
        // Drop into a specific box
        const targetBox = updatedBoxes.find((box) => box.id === toBoxId);
        if (targetBox) {
          targetBox.items = [...targetBox.items, { ...item, proficiency: targetBox.proficiency }];
        }

        // Remove the item from the initial items list to avoid duplicates
        setItems((prevItems) => prevItems.filter((i) => i.id.toString() !== itemId));
      }

      setBoxes(updatedBoxes);
    }
  };
  const handleRemoveFromIntial = (itemId) => {
    setItems((prevItems) => prevItems.filter((item) => item.id !== itemId));
    const termToRemove = data.find((term) => term.id === itemId);
    if (termToRemove) {
      // Remove the item from added expertise
      handleRemoveTerm(termToRemove);
    }
  };
  const handleRemoveFromBox = (boxId, itemId) => {
    const updatedBoxes = boxes.map((box) =>
      box.id === boxId ? { ...box, items: box.items.filter((item) => item.id !== itemId) } : box
    );
    setBoxes(updatedBoxes);

    // Remove the item from items list if it exists there
    handleRemoveFromIntial(itemId);
    const termToRemove = data.find((term) => term.id === itemId);
    if (termToRemove) {
      // Remove the item from added expertise
      handleRemoveTerm(termToRemove);
    }
  };
  const allBoxesEmpty = boxes.every((box) => box.items.length === 0);

  const TermWithPopup = withPopup(Label);
  return (
    <Modal open={open} className="addExpertise_secPopup">
      <Modal.Content>
        {(topicUpdating || skillUpdating) && (
          <Segment className="loading__segment">
            <Dimmer active inverted>
              <Loader size="large">{ADD_EXPERTISE.Saving}</Loader>
            </Dimmer>
          </Segment>
        )}
        <div className="addExpertise__popup-heading">
          {ADD_EXPERTISE.AddYourExpertise}
          <img
            src="./icons/cross-icon-black.svg"
            onClick={onModalClose}
            className="cancel_icon"
            alt="close"
            title="close"
          />
        </div>

        <div
          className="addExpertise_secPopup-expertise_section"
          onDrop={(e) => handleDrop(e, "initial")}
          onDragOver={(e) => e.preventDefault()}
        >
          <h3>{ADD_EXPERTISE.DragAndDrop}</h3>
          <List horizontal>
            {items.map((item) => (
              <ListItem key={item.id} draggable onDragStart={(e) => handleDragStart(e, item.id)}>
                <TermWithPopup
                  popupContent={<TermPath path={item?.path} />}
                  term={item}
                  handleRemoveFromIntial={() => handleRemoveFromIntial(item.id)}
                  name={item?.name}
                  minus="true"
                />
              </ListItem>
            ))}
          </List>
        </div>

        <div className="expertise_Level_Box">
          {boxes.map((box) => (
            <div
              className="dashed_border"
              key={box.id}
              onDrop={(e) => handleDrop(e, box.id)}
              onDragOver={(e) => e.preventDefault()}
            >
              <p className="level_name">{box.level}</p>

              <Popover
                trigger={
                  <p>
                    <Icon name="info circle" />
                    <span>{box.title}</span>
                  </p>
                }
                on="hover"
                inverted
                position="bottom center"
                popper={{ className: "clipboard-tooltip" }}
                size="tiny"
              >
                {box.info}
              </Popover>
              {box.items.length === 0 && <p className="dragDrop">{ADD_EXPERTISE.DragAndDropHere}</p>}
              {box.items.map((item) => (
                <div
                  key={item.id}
                  draggable
                  onDragStart={(e) => handleDragStart(e, item.id, box.id)}
                  style={{ border: "none" }}
                >
                  <Label
                    name={item?.name}
                    minus="true"
                    handleRemoveFromBox={() => handleRemoveFromBox(box.id, item.id)}
                    boxLabel="true"
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="align-items-center text-align-center">
          <Button className="previous_button" onClick={prevHandle}>
            {ADD_EXPERTISE.BackToSearch}
          </Button>
          {allBoxesEmpty || items?.length ? (
            <Popover
              trigger={
                <div style={{ display: "inline-block" }}>
                  <Button className="submit_button" disabled>
                    {ADD_EXPERTISE.Submit}
                  </Button>
                </div>
              }
              on="hover"
              inverted
              position="right center"
              popper={{ className: "clipboard-tooltip" }}
              size="tiny"
            >
              {ADD_EXPERTISE.DisableMsg}
            </Popover>
          ) : (
            <Button className="submit_button" onClick={handleSubmit}>
              {ADD_EXPERTISE.Submit}
            </Button>
          )}
        </div>
      </Modal.Content>
    </Modal>
  );
};

DragAndDrop.defaultProps = {
  open: false,
  onModalClose: () => {},
  prevHandle: () => {},
  handleRemoveTerm: () => {},
  handleClearAllFilter: () => {},
  data: []
};

DragAndDrop.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      proficiency: PropTypes.number.isRequired
    })
  ),
  onModalClose: PropTypes.func,
  prevHandle: PropTypes.func,
  handleRemoveTerm: PropTypes.func,
  handleClearAllFilter: PropTypes.func
};

export default DragAndDrop;
