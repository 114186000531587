import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import qs from "qs";
import { useQuery } from "helpers/utils";
import { useLocation } from "react-router-dom";
import styles from "./styles.module.scss";

/**
 * @param {string} placeholder placeholder text for the search box
 * @param {Function} onSearch callback function to have search logic
 * @param {string} classN provided class on input
 *
 * @returns Search component
 * @example
 * <Search placeholder="Search All Cases" onSearch={searchCases} />
 */

function Search({ placeholder, onSearch, classN, clickHandle, expertiseSearch }) {
  const inputRef = useRef(null);

  const location = useLocation();
  const { search } = location;
  const query = useQuery(search);

  useEffect(() => {
    inputRef.current.value = query.get("query");
  }, []);

  const handleOnChange = (e) => {
    onSearch(e.target.value);
  };

  return (
    <div className={`${styles["search"]} d-flex align-items-center `}>
      <input
        data-testid="txtSearch"
        type="search"
        ref={inputRef}
        placeholder={placeholder}
        className={`${styles["search__input"]} ${styles["active"]} ${styles[classN]}`}
        onChange={handleOnChange}
      />
      {expertiseSearch && <div className={styles["crossIcon"]} onClick={clickHandle} />}
    </div>
  );
}

Search.defaultProps = {
  classN: "",
  expertiseSearch: false,
  clickHandle: () => {}
};

Search.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  classN: PropTypes.string,
  expertiseSearch: PropTypes.bool,
  clickHandle: PropTypes.func
};

export default Search;
