import React, { useEffect, useRef, useState } from "react";
import { Button } from "semantic-ui-react";
import "./styles.scss";

function FilterIcon({ filterContents, hasFilters, disabled }) {
  const filterButtonRef = useRef(null);
  const [isActive, setIsActive] = useState(false);

  const handleOnClick = () => {
    setIsActive(!isActive);
  };

  const handleClickOutside = (event) => {
    if (filterButtonRef.current && !filterButtonRef.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={filterButtonRef} className="filter__dropdown__container">
      <Button
        icon="filter"
        color={hasFilters ? "red" : ""}
        className={`filter__button ${hasFilters ? "filter--black" : ""} ${isActive && "filter--active"}`}
        onClick={handleOnClick}
        disabled={disabled && !hasFilters}
      />
      {isActive && <div className="filter__dropdown">{filterContents}</div>}
    </div>
  );
}

export default FilterIcon;
