import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Card, Segment } from "semantic-ui-react";
import AccordionTable from "components/common/AccordionTable";
import AddExpertise from "components/AddExpertise";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { getProficiency } from "redux/actions/proficiency";
import { getDesignatedBy } from "redux/actions/designatedBy";
import { getExpertise, getExpertiseData } from "redux/actions/expertise";
import CONFIG from "constants/config";

function Expertise({ user, isEditable, peopleData }) {
  const { apiUrl, xApiKey } = CONFIG;
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const { expertiseData, expertiseDataLoading, expertiseDropDown, expertiseDropDownLoading } = useSelector(
    (state) => state
  );
  useEffect(() => {
    const endpoint = `${apiUrl.linkApi}profile/${user?.hrId}/expertise-paaf-tpoc?page=1&pageSize=5`;

    // Dispatch the API call
    dispatch(
      getExpertise({
        endpoint,
        config: {
          headers: {
            "x-api-key": `${xApiKey.profile}`
          }
        }
      })
    );
  }, [user?.hrId]);
  const handleAddExpertise = () => {
    console.log("working");
    setOpen(true);
  };

  useEffect(() => {
    dispatch(
      getDesignatedBy({
        endpoint: `${apiUrl.linkApi}profile/ref/designatedby`,
        config: {
          headers: {
            "x-api-key": `${xApiKey.profile}`
          }
        }
      })
    );
    dispatch(
      getProficiency({
        endpoint: `${apiUrl.linkApi}profile/ref/proficiency`,
        config: {
          headers: {
            "x-api-key": `${xApiKey.profile}`
          }
        }
      })
    );
  }, [dispatch]);

  const hasExpertiseData = expertiseData?.[0]?.expertise?.length > 0;
  console.log(open, "hello");
  return (
    <Card className="expertise_card">
      <Card.Content>
        {hasExpertiseData ? (
          <>
            <div className="Heading_section d-flex justify-content-between align-items-center">
              <div>
                <Card.Header>Expertise</Card.Header>
                <Card.Meta>Sorted by most recent case</Card.Meta>
              </div>
              <div>
                <Button className="add__expertise--btn">Add Excel</Button>
                <Button className="add__expertise--btn" onClick={handleAddExpertise}>
                  Add Expertise
                </Button>
              </div>
            </div>
            {open && <AddExpertise open={true} onModalClose={() => setOpen(false)} />}
          </>
        ) : (
          <div className="no_expertise"></div>
        )}
      </Card.Content>
      <AccordionTable
        user={user}
        isEditable={isEditable}
        peopleData={peopleData}
        hasExpertiseData={hasExpertiseData}
        handleAddExpertise={handleAddExpertise}
      />
    </Card>
  );
}

Expertise.defaultProps = {
  user: {},
  isEditable: false
};

Expertise.propTypes = {
  user: PropTypes.shape({
    hrId: PropTypes.number
  }),
  isEditable: PropTypes.bool,
  peopleData: PropTypes.shape().isRequired
};

export default Expertise;
