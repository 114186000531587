const BOXES = () => [
  {
    id: 101,
    level: "Thought Leader",
    title: "7+ years pre-BCG experience/post grad educational experience OR 10+ BCG cases OR 60+ months experience",
    items: [],
    proficiency: 1,
    info: (
      <>
        <ul style={{ paddingRight: "10px" }}>
          <li>7+ years pre-BCG experience/post grad educational experience OR</li>
          <li>10+ BCG cases OR</li>
          <li>60+ months experience within the topic</li>
          <li>Materially contributed to 20+ proposals</li>
          <li>Viewed internally and by clients as go-to person for the topic</li>
        </ul>

        <p>
          <strong>Indicative, not required:</strong>
        </p>
        <ul>
          <li>Publications in BCG perspectives or external journals</li>
          <li>Regular speaker at forums/conferences</li>
          <li>Faculty member at prestigious university</li>
        </ul>
      </>
    )
  },
  {
    id: 102,
    level: "Advanced",
    title: "4-6 years pre-BCG experience/post grad educational experience OR 8+ BCG cases OR 30+ months experience",
    items: [],
    proficiency: 2,
    info: (
      <>
        <ul>
          <li>2-3 years pre-BCG experience/post grad educational experience OR</li>
          <li>4+ BCG cases OR</li>
          <li>18+ months experience within the topic</li>
        </ul>
      </>
    )
  },
  {
    id: 103,
    level: "Experienced",
    title: "2-3 years pre-BCG experience/post grad educational experience OR 4+ BCG cases OR 18+ months experience",
    items: [],
    proficiency: 3,
    info: (
      <>
        <ul>
          <li>Deep, hands-on experience with the topic</li>
          <li>2-3 years pre-BCG experience/post grad educational experience OR</li>
          <li>4+ BCG cases OR</li>
          <li>18+ months experience within the topic</li>
        </ul>
      </>
    )
  },
  {
    id: 104,
    level: "Basic",
    title: "0-2 years pre-BCG experience/post grad educational experience OR 2+ BCG cases OR 6+ months experience",
    items: [],
    proficiency: 4,
    info: (
      <>
        <ul>
          <li>0-2 years pre-BCG experience/post grad educational experience OR</li>
          <li>2+ BCG cases OR</li>
          <li>6+ months experience within the topic</li>
        </ul>
      </>
    )
  }
];
export default BOXES;
